.shopcategory-banner{
    display: block;
    margin: 30px auto;
    width: 80%;
}
.shopcategory-indexSort{
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}
.shopcategory-sort{
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
}
.shopcategory-indexSort p span{
    font-weight: 600;
}
.shopcategory-products{
    width: 90%;
    max-width: 1360px;
    justify-content: center;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    
}
.shopcategory-loadmore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
    width: 200px;
    height: 60px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 16px;
    font-weight: 500;
}
@media (max-width:1280px) {
    .shopcategory-banner{
        margin: 20px auto;
        width: 90%;
    }
    .shopcategory-indexSort{
        width: 90%;
       margin: auto;
    }
    .shopcategory-sort{
        padding: 5px 10px;
        font-size: 12px;
    }
    .shopcategory-indexSort p{
        font-size: 12px;
    }
}
@media (max-width:800px) {
    .shopcategory-banner{
        width: 95%;
    }
     .shopcategory-loadmore{
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 13px;
    }
}
