.item{
    width: 21%;
}
.item img{
    width: 100%;
}
.item p{
    margin: 6px 0px;
}
.item-prices{
    display: flex;
    gap: 15px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.item:hover{
    transform: scale(1.05);
    transition: 0.4s;
    box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;;
}


@media(max-width:800px){
    .item{
        width: 35%;
    }
    .item-price-old{
        font-size: 12px;
    }
}
@media(max-width:500px){
    .item p{
        font-size: 14px;
    }
    .item-price-new{
        font-size: 14px;
    }
}